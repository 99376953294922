// App main
const main = async() => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    
    // load the vue waypoint
    const { default: VueWaypoint } = await import(/* webpackChunkName: "vuewaypoint" */ 'vue-waypoint');
    Vue.use(VueWaypoint)

    // Create our vue instance
    const vm = new Vue({
        el: "#block-container",
        components: {
            'modal': () => import(/* webpackChunkName: "modal" */ '@/vue/Modal.vue'),
            'arguments': () => import(/* webpackChunkName: "modal" */ '@/vue/Arguments.vue'),
        },
        data: function() {
            return {
                modalLink : '',
                intersectionOptions: {
                    threshold: [0.35]
                }
            };
        },

        methods: {
            onWaypoint({ el, going, direction }) {
                if( this.$waypointMap.GOING_IN === going ) {
                    el.classList.add('active')
                }
            },

            onComponentModal(url) {
                this.modalLink = url
            },

            loadModal(event) {
                var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
                console.log( event )
                if( event.target.href ) {
                    this.modalLink = event.target.href
                }
                else if( event.target.closest('a') ) {
                    if( isIE11 ) {
                        window.location.href = event.target.closest('a').href
                    } else {
                        this.modalLink = event.target.closest('a').href
                    }
                }
            },

            onModalClose() {
                this.modalLink = ''
            },

            resizeGridItem(item){
                let grid      = document.getElementsByClassName("alternatingQuestions")[0];
                let rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
                let rowGap    = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
                let rowSpan   = Math.ceil((item.querySelector('.content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
                
                item.style.gridRowEnd = "span "+rowSpan;
            },

            resizeAllGridItems(){
                let allItems = document.getElementsByClassName("item");
                for( let x=0; x<allItems.length; x++ ){
                    this.resizeGridItem(allItems[x]);
                }
            }
             
        },

        mounted() {
            this.resizeAllGridItems()
            window.addEventListener("resize", this.resizeAllGridItems);
        },
    });
};

// Execute async function
main().then((value) => {
    
});